 {
  /* stylelint-enable selector-class-pattern */
}
.search-1axtC {
    display: flex;
    background-color: var(--aluminum1);
    margin: 0 calc(var(--padding-lg) * -1) var(--padding-sm);
    padding: var(--padding-lg) var(--padding-lg) 0;
    border-bottom: 1px solid var(--aluminum3);
    flex-grow: 1;
    justify-content: space-between;
  }
.search-1axtC > div {
      width: 100%;
    }
.search-1axtC .search {
      background-color: var(--aluminum1);
    }
/* Mobile */
@media (max-width: 479px) {
.search-1axtC {
      flex-direction: column-reverse
  }
    }
.master-organization-2Rw_n {
    color: var(--aluminum5);
    margin-left: var(--padding-xs);
    font-size: 12px;
  }
/* stylelint-disable selector-class-pattern */
.multiSelectFilter .btnMain .master-organization-2Rw_n {
      display: none;
    }
/* stylelint-disable selector-class-pattern */
.search {
    color: inherit;
    font-size: 14px;
  }
.search .mainFilters .filterPanel + .filterPanel {
      margin-left: 0;
    }
.searchPanel {
    padding: var(--padding-lg) var(--padding-lg) var(--padding-md);
  }
.filterPanel.ang-search-component-filter-panel:empty {
    display: none;
  }
.mainFiltersPanel {
    padding: 0 var(--padding-lg) var(--padding-sm);
    border-bottom: 0;
  }
.mainFiltersPanel .mainFilters:empty + .advancedFiltersToggle {
      display: none;
    }
.mainFiltersPanel .filterPanel {
      margin-left: 0;
      margin-right: var(--padding-sm);
      margin-bottom: var(--padding-sm);
    }
/* hides advanced button */
.mainFiltersPanel .advancedFiltersButton:first-child {
      display: none;
    }
.mainFiltersPanel .advancedFiltersToggle hr {
        display: none;
      }
.mainFiltersPanel .advancedFiltersToggle button.advancedFiltersButton {
        height: auto;
        height: initial;
        margin-bottom: var(--padding-sm);
      }
.mainFiltersPanel .multiSelectFilter .ul {
        overflow-y: auto;
      }
.mainFiltersPanel .multiSelectFilter .dropdown {
        /* stylelint-disable-next-line max-line-length */
        z-index: 11;
      }
/* https://github.com/mulesoft/anypoint-components/blob/ecd001cbbae47ce8791ce869bd321745056e6ce3/src/components/Checkbox/themes/DefaultTheme.css#L16 💀 */
.mainFiltersPanel .multiSelectFilter .dropdown .ul .li .label {
          max-width: 200px;
        }
.mainFiltersPanel .multiSelectFilter .btnMain {
        display: flex;
      }
.mainFiltersPanel .multiSelectFilter .multiSelectBtn:hover {
        background-color: var(--aluminum2);
      }
.secondaryFiltersPanel {
    margin: 0 8px;
  }
.secondaryFiltersPanel .group {
      padding: 0;
    }
.secondaryFiltersPanel .groupLabel {
      margin: 0 8px var(--padding-sm);
    }
.secondaryFiltersPanel .datePickerFilter {
      margin-top: 0;
    }
.secondaryFiltersPanel .filterLabel {
      display: block;
      margin: 0 0 var(--padding-sm);
    }
.secondaryFiltersPanel .filterPanel {
      margin-left: 0;
    }
.secondaryFiltersPanel .secondaryFiltersFooter {
      padding: 10px 0;
    }
[data-test-id="test-asset-minor-versions.VersionNode.generatedFrom"] {
    display: none;
  }
/* stylelint-enable selector-class-pattern */
